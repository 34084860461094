.create-update-resource-form {
	// color: #000;
	font-size: 1.2rem;

	&__title {
		margin: 1rem 0 2rem;
	}

	.field-description {
		display: none;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: none;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		margin-bottom: 2rem;
	}

	.checkbox label {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}

	.form-group {
		display: flex;
		gap: 1rem;

		.control-label {
			width: 3rem;
		}
	}

	.btn {
	}

	&__thumb {
		width: 10rem;
		height: 10rem;
		object-fit: cover;
	}

	&__buttons {
		display: flex;
		gap: 1rem;
	}
}
