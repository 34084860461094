.login {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;

	&__container {
		padding: 2rem;
		background: rgba(255, 255, 255, 0.05);
		background: rgba($psmixed, 0.7);
		backdrop-filter: blur(40px);
		padding: 2rem;
		animation: modalcontent 0.1s ease-out both;
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		border: 1px solid rgba(#fff, 0.1);

		display: flex;
		flex-direction: column;
		gap: 1rem;

		&__logo {
			width: 20rem;
			margin: 1rem 0 1rem;
		}
	}
}
