.palette {
	&__content {
		display: flex;

		&__list {
			margin: 0;
			padding: 0;
			list-style: none;
			height: 60vh;
			border-right: 1px solid rgba(#fff, 0.2);
			min-width: 10vw;
			overflow: auto;

			.sortable {
				&__item {
					display: flex;
					gap: 1rem;
					align-items: center;
					cursor: pointer;

					border-bottom: 1px solid rgba(#fff, 0.2);
					line-height: 2rem;

					&--selected {
						background: rgba(#fff, 0.2);
					}

					&__handle {
						width: 0.5rem;
						height: 2rem;
						cursor: move;
						background: rgba(#fff, 0.4);
					}

					&__content {
						flex: 1;
					}
				}
			}
		}

		&__view {
			width: 50vw;
			padding: 0 2rem 2rem;
			max-height: 60vh;

			h2 {
				margin: 2rem 0 1rem;
			}

			h4 {
				margin: 2rem 0 1rem;
			}

			.sortable {
				max-height: 35vh;
				overflow: auto;
				&__item {
					margin: 0.5rem;
					display: inline-flex;
					align-items: center;
					cursor: pointer;

					&__handle {
						width: 0.5rem;
						height: 2rem;
						cursor: move;
						background: rgba(#fff, 0.4);
					}

					&__content {
						flex: 1;
					}
				}
			}
		}
	}
}

.viewPalette {
	position: relative;

	&__delete {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
}

.paletteRow {
	border-radius: 4px;
	background-color: rgba(#fff, 0.1);
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
	font-size: 0;
	border: 1px solid $gold;
	padding: 0.2rem;

	> * {
		display: inline-block;
		vertical-align: middle;
	}

	&__warning {
		height: 1.618rem;
		width: 1.618rem;

		.icon {
			width: 100%;
			height: 100%;
		}
	}

	&--static .color {
		cursor: auto;
	}

	&__color {
		margin-right: 0.2rem;
		border-right: 1px solid $gold;
		padding-right: 0.2rem;

		.color {
			border-radius: 50%;
		}
	}

	&__variationsContainer {
		display: inline-block;
	}

	&__variations {
		display: flex;
		gap: 0.1rem;

		&__variation {
		}
	}

	&__save {
	}
}
