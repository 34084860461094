.resource-path-selector {
	&__head {
		display: flex;
	}

	&__path {
		display: flex;
		gap: 1rem;

		> * {
			position: relative;
		}
		> * + * {
			&:after {
				position: absolute;
				left: -0.5rem;
				top: 0;
				transform: translateX(-50%);
				display: block;
				content: '/';
			}
		}
	}
}
