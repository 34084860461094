@keyframes prompt-focus-animation {
	from {
		transform: translateX(-50%) scale(1.05);
		box-shadow: 0 1rem 1rem rgba(20, 20, 20, 0);
	}
	to {
		transform: translateX(-50%) scale(1);
		box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
	}
}

#prompt-root,
#alert-root {
	&.open {
		position: fixed;
		z-index: 100000000;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
	.prompt,
	.alert {
		position: fixed;
		z-index: 1000000000;
		top: 1rem;
		left: 50%;
		transform: translateX(-50%);
		background: rgba(#fff, 0.05);
		border: 1px solid rgba(#fff, 0.2);
		backdrop-filter: blur(20px);

		box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
		padding: 1rem;
		border-radius: 0 0 2px 2px;

		&.prompt--focus {
			animation: prompt-focus-animation 0.3s both;
		}

		&__label {
			font-size: 1rem;
			font-weight: bold;
			margin-bottom: 1rem;
		}
		&__input {
			width: 20rem;
			line-height: 1;
			background: #111;
			border: none;
			margin-right: 1rem;
			padding: 1rem;
		}

		&__confirm_buttons {
			display: flex;
			gap: 1rem;
			justify-content: flex-end;
		}

		&__button {
			border: none;
			color: rgba(255, 255, 255, 0.8);

			cursor: pointer;

			&:hover {
				color: rgba(255, 255, 255, 1);
			}
		}
	}
}
