.create-element {
	font-size: 1.2rem;

	&__title {
		margin: 1rem 0 2rem;
	}

	&__content {
		&__head {
			display: flex;

			border-bottom: 1px solid rgba(#fff, 0.2);

			&__item {
				text-align: center;
				flex: 1;
				cursor: pointer;
				border-bottom: 2px solid transparent;

				&--open {
					border-bottom-color: rgba(#fff, 1);
				}
			}
		}

		&__content {
			width: 60vw;
			height: 60vh;
			overflow: auto;
		}

		&__footer {
			border-top: 1px solid rgba(#fff, 0.2);
			padding-top: 1rem;
		}
	}
}

.create-element__slide {
	&__meta {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;

		&__input {
			width: 100%;
			margin-bottom: 2rem;
		}

		&__categories {
			&__selecteds {
				list-style: none;
				margin: 0;
				padding: 0;

				&__item {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&__svg {
		padding: 1rem 0;

		&__preview {
			flex: 1;

			.dropzone {
				width: 100%;
				height: 10vw;
			}
			&__svg {
				position: sticky;
				top: 0;
				border: 1px solid rgba(#fff, 0.2);
			}
		}

		&__thumb {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			justify-content: center;

			&__line {
				display: flex;
				align-items: center;
				gap: 0.5rem;
			}

			&__image {
				font-size: 0;
				width: 6rem;

				border: 1px solid rgba(#fff, 0.2);
			}

			&__generate {
				width: 6rem;
				font-size: 0;
				cursor: move;
				user-select: none;
				border: 1px solid rgba(#fff, 0.2);

				canvas {
					width: 100%;
				}
			}
		}

		&__mutable-colors {
			flex: 1;

			&__selecteds {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				margin: 1rem 0;

				&__item {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
			}

			&__item {
				border: 1px solid rgba(#fff, 0.2);
				border-width: 1px 0;
				padding: 0.5rem 0;
			}

			.color--selected {
				border-width: 3px;
				border-radius: 50%;
			}
		}
	}
}
