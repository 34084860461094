@keyframes MessageAnimation {
	from {
		transform: translate(0%, 0%) scale(0.8);
	}
	to {
		transform: translate(0%, 0%) scale(1);
	}
}

.messages {
	position: fixed;
	bottom: 0;
	left: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 100000000000000000000;

	&__message {
		backdrop-filter: blur(40px);
		box-shadow: 0 1rem 2rem rgba(#000, 0.2);
		border-radius: 4px;
		background: rgba(#fff, 0.1);
		border: 1px solid rgba(#fff, 0.1);
		color: #fff;
		padding: 0.5rem;
		margin: 1rem;
		transform-origin: left center;
		animation: MessageAnimation 0.1s ease-out both;

		&--error {
			color: rgba(#faa, 1);
			box-shadow: 0 1rem 2rem rgba(#f00, 0.2);
			background: rgba(#f00, 0.1);
			border: 1px solid rgba(#f00, 0.1);
		}

		&--success {
			color: rgba(#afa, 1);
			background: rgba(#0f0, 0.1);
			box-shadow: 0 1rem 2rem rgba(#0f0, 0.2);
			border: 1px solid rgba(#0f0, 0.1);
		}
	}
}
