@import 'inputs';
@import 'modal';
@import 'select-resource';
@import 'resource-path-selector';
@import 'sortable';
@import 'create-update-resource-form';
@import 'prompt-alert';
@import 'dropzone';
@import 'color';
@import 'colorPick';
@import 'icon';
@import 'messages';
@import 'show-resource-path';
@import 'paginator';

.list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.list--column {
	@extend .list;
	gap: 1rem;
	display: flex;
	flex-direction: row;
}
