.dropzone {
	border: 1px dashed rgba(#fff, 0.2);
	padding: 1rem;
	display: inline-block;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	&--can-drop {
		border-color: $tertiary;
		opacity: 0.2;
	}

	&__input {
		display: none;
	}
	&__label {
		cursor: pointer;
		display: inline-block;
		margin-top: 0.2rem;
		background-color: rgba(#fff, 0.1);
		border: 1px solid rgba(#fff, 0.2);
		padding: 0 0.4rem;
		border-radius: 2px;
	}

	&__message {
		line-height: 1.618rem;
		display: inline-block;
		vertical-align: middle;
	}

	&__button {
		margin-top: 0.5rem;
	}
}
