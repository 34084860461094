.users {
	max-width: 1100px;
	margin: 5vh auto;

	border: 1px solid rgba(#fff, 0.2);
	padding: 1rem;
	border-radius: 0.25rem;

	.paginatorBar {
		margin: 0 0 1rem 0;
		padding: 0.5rem 0;
		border-top: 1px solid rgba(#fff, 0.2);
		border-bottom: 1px solid rgba(#fff, 0.2);
	}

	&__head {
		display: flex;
		padding: 0 0.25rem 0.25rem;

		&__item {
			font-weight: bold;
			flex: 1;

			&--cliccable {
				cursor: pointer;
			}
		}
	}

	&__head,
	&__list__item {
		display: flex;
	}
	&__head__item,
	&__list__item > div {
		&:nth-child(1) {
			flex: 0.5;
		}
		&:nth-child(2),
		&:nth-child(3) {
			flex: 1;
		}
		&:nth-child(4) {
			flex: 0.3;
		}
		&:nth-child(5) {
			flex: 2;
		}
	}

	&__list {
		&__item {
			&:nth-child(2n + 1) {
				background-color: rgba(#fff, 0.1);
			}
			padding: 0 0.25rem;
			line-height: 2rem;

			& + & {
				border-top: 1px solid rgba(#fff, 0.1);
			}

			&__scopes {
				font-size: 0.8rem;
				display: flex;
				gap: 0.5rem;
				align-items: center;

				> div {
					display: flex;
					align-items: center;
					gap: 0.2rem;
				}
			}
		}
	}
}

.users__create {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&__scopes {
		display: flex;
		gap: 1rem;
	}
}
