input::placeholder,
textarea::placeholder {
	color: rgba(#fff, 0.6);
	font-style: italic;
}

textarea {
	margin: 0;
	background: rgba(#fff, 0.1);
	border: 1px solid rgba(#fff, 0.4);
	border-radius: 2px;
	outline: none;
	padding: 0 0.4em 0.1em;
	color: #fff;
	font-family: Dosis, sans-serif;
	font-size: 1em;
}

input,
select {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	height: 1.618em;
	background: rgba(#fff, 0.1);
	border: 1px solid rgba(#fff, 0.4);
	border-radius: 2px;
	outline: none;
	padding: 0 0.4em 0.1em;
	color: #fff;
	font-family: Dosis, sans-serif;
	font-size: 1em;
}

select option {
	color: #000;
	background: rgba(0, 0, 0, 0.3);
}

button {
	&:disabled {
		opacity: 0.2;
		pointer-events: none;
		&:after {
			opacity: 0;
		}
	}

	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	background: $tertiary;
	border-radius: 2px;
	color: #fff;
	text-shadow: 0 1px 0px rgba(#000, 0.8);
	border: none;
	height: 1.618rem;
	padding: 0 0.8rem;
	// text-transform: uppercase;
	letter-spacing: 0.02rem;
	font-family: Dosis, sans-serif;
	font-size: 0.8rem;

	&:hover {
		background: lighten($tertiary, 2%);
	}
	&:after {
		position: absolute;
		top: 0.3rem;
		left: 0.3rem;
		display: block;
		content: ' ';
		border-radius: inherit;
		width: 100%;
		height: 100%;
		background: rgba(darken($secondary, 10%), 0.5);
		z-index: -1;
	}

	&.button {
		&--secondary {
			height: 1.618rem;
			border-radius: 0.2rem;
			padding: 0 0.4rem;
			text-transform: none;
			background: $secondary;
			border: 1px solid $psmixed;
			border-bottom-width: 2px;

			&:after {
				display: none;
			}
		}
	}
}
