.color {
	display: inline-block;
	width: 1.618rem;
	height: 1.618rem;
	border-radius: 2px;
	border: 1px solid transparent;
	cursor: pointer;

	transition: 0.08s ease-in;

	&--light {
		border-color: rgba(#000, 0.2);
	}
	&--dark {
		border-color: rgba(#fff, 0.2);
	}

	&--light.color--selected {
		border-color: #000;
	}
	&--dark.color--selected {
		border-color: #fff;
	}

	&--withLabel {
		width: auto !important;
		height: auto !important;
		padding: 0.05rem 0.1rem;
		font-size: 0.8rem;
		border-radius: 4px;
	}

	&--light {
		color: #000;
	}
	&--dark {
		color: #fff;
	}
}
