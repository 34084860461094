.app {
	width: 100vw;
	height: 100vh;

	&__head {
		height: $appHeadHeight;
		display: grid;
		align-items: center;
		grid-template-columns: 1fr auto auto;
		gap: 1rem;
		padding: 0 1.5rem;

		&__logo {
			height: calc(#{$appHeadHeight} - 3rem);
		}

		&__nav {
			display: flex;
			gap: 1rem;

			&__link {
				color: #fff;
				text-decoration: none;

				&--active {
					border-bottom: 1px solid #fff;
				}
			}
		}
	}

	&__logo {
		height: 2rem;
	}

	&__content {
		height: calc(100vh - #{$appHeadHeight});
		overflow: auto;

		&__homepage {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			color: #fff;

			a {
				color: inherit;
				& + a {
					margin-left: 1rem;
				}
			}
		}
	}
}
