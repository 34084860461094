.colorPick {
	position: relative;

	&__pick {
		position: fixed;
		border-radius: 2px;
		background: #fff;
		padding: 0.2rem;
		display: flex;
		z-index: 1000000;
		color: #000;

		flex-direction: column;
		gap: 0.5rem;

		.material-picker {
			width: 8rem !important;
			height: 8rem !important;
			font-family: inherit !important;
		}

		&__save {
			display: flex;
			justify-content: space-between;
			color: $secondary;

			span {
				padding: 0 0.2rem;
				cursor: pointer;
			}
		}

		input {
			color: inherit;
		}
	}

	&__color {
	}
}
