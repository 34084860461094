.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;

	&__background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		animation: modalbackground 0.1s ease-out both;
		// background: rgba(0, 0, 0, 0.1);
	}

	&--nopadding &__content {
		padding: 0;
	}

	&__content {
		position: relative;
		z-index: 1000;
		max-width: 80vw;
		background: rgba(255, 255, 255, 0.05);
		background: rgba($psmixed, 0.7);
		backdrop-filter: blur(40px);
		padding: 2rem;
		animation: modalcontent 0.1s ease-out both;
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		border: 1px solid rgba(#fff, 0.1);
		max-height: 90vh;
		overflow: auto;

		&__close_btn {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			cursor: pointer;

			svg path {
				fill: #fff;
			}
		}
	}
}

@keyframes modalbackground {
	from {
		opacity: 0;
		backdrop-filter: blur(0px);
	}
	to {
		opacity: 1;
		backdrop-filter: blur(2px);
	}
}
@keyframes modalcontent {
	from {
		opacity: 0;
		transform: scale(0.98);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
