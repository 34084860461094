.payments {
	max-width: 1100px;
	margin: 5vh auto;

	border: 1px solid rgba(#fff, 0.2);
	padding: 1rem;
	border-radius: 0.25rem;

	.paginatorBar {
		margin: 0 0 1rem 0;
		padding: 0.5rem 0;
		border-top: 1px solid rgba(#fff, 0.2);
		border-bottom: 1px solid rgba(#fff, 0.2);

		select,
		input[type='date'] {
			max-width: 8rem;
		}
	}

	&__head {
		display: flex;
		padding: 0 0.25rem 0.25rem;

		&__item {
			font-weight: bold;
			flex: 1;

			&--cliccable {
				cursor: pointer;
			}
		}
	}

	&__head,
	&__list__item {
		display: flex;
		gap: 1rem;
	}
	&__head__item,
	&__list__item > div {
		flex: 1;

		&:nth-child(1) {
			flex: 0.5;
		}
		&:nth-child(2) {
			flex: 0.5;
		}
		&:nth-child(6),
		&:nth-child(7) {
			flex: 0.5;
		}
		&:nth-child(8) {
			flex: 1;
		}
	}

	&__list {
		&__item {
			&:nth-child(2n + 1) {
				background-color: rgba(#fff, 0.1);
			}
			padding: 0 0.25rem;
			line-height: 2rem;

			& + & {
				border-top: 1px solid rgba(#fff, 0.1);
			}
			small {
				font-size: 0.7rem;
			}
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;

		&__total {
			display: flex;
			gap: 0.5rem;
		}
	}
}

.payments .paginatorBar {
	&__gateways {
		display: flex;
		gap: 0.5rem;
	}
}
