.select-resource {
	position: relative;
	display: inline-block;

	&__input:focus {
		z-index: 10;
	}

	&__input:focus + &__options,
	&__options:hover {
		display: block;
	}

	&__create {
	}

	&__options {
		background: $psmixed;
		color: #fff;
		// backdrop-filter: blur(100px);
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 100%;
		padding: 0;
		border: 1px solid lighten($psmixed, 10%);
		margin: 0;

		list-style: none;
		display: none;
		z-index: 1000;

		&__option {
			cursor: pointer;
			padding: 0.2rem 0.4rem;

			&:hover {
				background: lighten($psmixed, 10%);
			}
		}

		&__empty {
			white-space: nowrap;
			font-size: 0.8rem;
			font-style: italic;
		}
	}
}
