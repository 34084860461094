.show-resource-path {
	border: 1px solid rgba(#fff, 0.2);
	display: inline-block;
	border-radius: 2px;
	background-color: rgba(#fff, 0.1);
	margin: 0;
	padding: 0 0.5rem;
	list-style: none;

	&__item {
		display: inline-block;

		& + & {
			margin-left: 0.25rem;
		}

		&:not(:last-child):after {
			margin-left: 0.25rem;
			display: inline-block;
			content: '/';
			color: rgba(#fff, 0.2);
		}
	}
}
