html,
body {
	margin: 0;
}

* {
	box-sizing: border-box;
}

html {
	font-family: 'Dosis', cursive;
}

body {
	// background: $primary;

	background: linear-gradient(90deg, $primary 0%, $psmixed 40%, $secondary 100%);
	color: #fff;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 0.5em;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
	// border-radius: 4px;
}
::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.05);
	// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.2);
}

#root {
	height: 100%;
	width: 100%;
}

.react-icons {
	vertical-align: middle;
}

hr {
	margin: 0.5rem 0;
	border-color: rgba(#fff, 0.2);
}
