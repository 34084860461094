.creators {
	padding: 5vh;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	gap: 1rem;

	&__info {
		flex-direction: column;
		align-content: space-between;
		display: flex;
		gap: 1rem;
		overflow: hidden;

		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			line-height: 1;
			gap: 0rem;
			text-decoration: none;
			font-size: 1rem;
			color: inherit;

			b {
				font-size: 4rem;
			}
		}
	}

	&__content {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		overflow: auto;
	}

	&__footer {
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		gap: 0.5rem;

		> * {
			position: relative;
			z-index: 1000;
			margin: 20px;
			padding: 5px;
			cursor: pointer;
		}
	}
}

.creator-resources {
	height: 100%;

	&__container {
		&__path {
			color: rgba(#fff, 0.3);
			&__info {
				color: rgba(#fff, 0.6);
				padding-right: 0.25rem;
			}
			&:not(:first-child) {
				margin-top: 3vh;
			}
			font-size: 0.8rem;
			margin-bottom: 1vh;

			span {
				margin: 0 0.2rem;
				font-weight: bold;
			}
		}
		&__list {
			.sortable__item,
			&__add {
				vertical-align: middle;
				display: inline-block;
				margin: 0.5rem;

				background: rgba(255, 255, 255, 0.1);
				border: 1px solid rgba(255, 255, 255, 0.4);
			}

			&__add {
				opacity: 0.4;
				padding: 1.55rem;
				text-align: center;

				cursor: pointer;

				&:hover {
					opacity: 1;
				}

				svg path {
					stroke: #fff;
				}
			}

			.sortable {
				display: inline-block;

				&__item {
					&__handle {
						background: rgba(255, 255, 255, 0.5);
						height: 0.5rem;
						cursor: move;
					}

					&--selected {
						border-color: $tertiary;

						.sortable__item__handle {
							background: rgba($tertiary, 0.6);
						}
					}
				}
			}
		}
	}
}

.creator-resource {
	&__container {
		padding: 0.5rem;
	}
	&__image {
		border: 1px solid rgba(255, 255, 255, 0.1);
		width: 4.5rem;
		height: 4.5rem;
		transition: 0.3s;
		overflow: hidden;
		object-fit: contain;
	}

	&__name {
		margin: 0;
	}
	&__visibility {
		&__status {
			display: inline-block;
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			margin-right: 0.25rem;

			&--visible {
				background: #0f0;
			}
			&--hidden {
				background: #f00;
			}
		}
	}

	&__edit,
	&__open {
		background: rgba(#fff, 0.2);
		border-top: 1px solid rgba(#fff, 0.2);
		line-height: 0;
		cursor: pointer;
		text-align: center;
		transition: background 0.1s;
		padding: 0.05rem 0;
		&:hover {
			background: rgba(#fff, 0.5);
		}
	}

	&__edit {
		flex: 1;
		border-left: 1px solid rgba(#fff, 0.2);
	}
	&__open {
		flex: 2;
	}
}
