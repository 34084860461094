.paginatorBar {
	gap: 1rem;

	&,
	&__size,
	&__page {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__size,
	&__page {
		gap: 0.5rem;
	}

	&__size {
	}

	&__page {
	}

	&__search {
		display: flex;
		gap: 0.5rem;
	}
}
